import { SpaceBetween, StatusIndicator, Button } from '@amzn/awsui-components-react';
import React from 'react';
import { DeprecatedCharge } from 'src/interface/type-def';
import { FieldType } from './map-to-schema';
import { TemplatePropertySuggestedValues } from '../../templateSuggestions/templateSuggestionsUtils';

interface ChargesSummaryProps {
  charges: DeprecatedCharge[];
  makeActive: Function;
  removeItemFromList: Function;
  meterIndex?: number;
  suggestedTemplateValues: TemplatePropertySuggestedValues | null;
}

export const ChargesSummary = ({
  charges,
  makeActive,
  removeItemFromList,
  meterIndex,
  suggestedTemplateValues,
}: ChargesSummaryProps) => {
  // possibly too simple evaluation of a charge record being ready
  const isChargeComplete = (charge: DeprecatedCharge) => !!charge.chargeId;
  const chargesSummary = charges.map((charge, idx) => (
    <SpaceBetween size="s" direction="horizontal" key={idx}>
      <span>{isChargeComplete(charge) ? <StatusIndicator type="success" /> : <StatusIndicator type="error" />}</span>
      <Button
        iconName="edit"
        variant="inline-icon"
        onClick={() => makeActive(idx, meterIndex !== undefined ? FieldType.METERS_CHARGES : FieldType.CHARGES)}
      />
      <Button
        iconName="close"
        variant="inline-icon"
        onClick={() =>
          removeItemFromList(idx, meterIndex !== undefined ? FieldType.METERS_CHARGES : FieldType.CHARGES, meterIndex)
        }
      />
      <span>Charge #{idx}</span>
    </SpaceBetween>
  ));
  return <div>{chargesSummary}</div>;
};
