import { UtilityActivity } from '@amzn/taprootinvoicemanagementservice-ts-client';
import { ErrorState, SetMultiFieldErrorProps, SetMultiFieldProps } from 'src/components/manifests/hooks/type';
import { useInvoiceManualTranscriptionGenericForm } from 'src/components/manifests/hooks/useInvoiceManualTranscriptionGenericForm';

type UtilityActivityFieldName = keyof UtilityActivityFormFieldState;
type UtilityActivityErrorFields = Array<Set<UtilityActivityFieldName>>;

export type UtilityActivityFormFieldState = {
  utilityType: string;
  totalUsageAmount: string;
  totalUsageUnit: string;
  utilityChargeAmount: string;
  utilityChargeCurrency: string;
  peakDemandUsageAmount: string;
  peakDemandUsageUnit: string;
};

type UtilityActivityErrorsState = ErrorState<UtilityActivityErrorFields>;
export type SetUtilityFieldProps = SetMultiFieldProps<UtilityActivityFieldName>;
export type SetUtilityErrorProps = SetMultiFieldErrorProps<UtilityActivityFieldName>;

export const RequiredUtilityFields = new Set<UtilityActivityFieldName>([
  'utilityType',
  'utilityChargeAmount',
  'utilityChargeCurrency',
  'totalUsageUnit',
  'totalUsageAmount',
]);

const initializeUtilityActivityValues = (
  utilityActivities: Array<UtilityActivity>
): Array<UtilityActivityFormFieldState> =>
  utilityActivities.map((activity) => ({
    utilityType: activity.utilityType ?? '',
    utilityChargeAmount: activity.utilityCharge?.amount?.toString() ?? '',
    utilityChargeCurrency: activity.utilityCharge?.currency ?? '',
    totalUsageAmount: activity.totalUsage?.amount?.toString() ?? '',
    totalUsageUnit: activity.totalUsage?.unit ?? '',
    peakDemandUsageAmount: activity.peakDemand?.amount?.toString() ?? '',
    peakDemandUsageUnit: activity.peakDemand?.unit ?? '',
  }));

export const useUtilityForm = (initialUtilityActivities: Array<UtilityActivity>) => {
  const utilityForm = useInvoiceManualTranscriptionGenericForm<UtilityActivityFormFieldState, UtilityActivityFieldName>(
    initialUtilityActivities,
    RequiredUtilityFields,
    initializeUtilityActivityValues
  );

  const { fields, errors, errorCount, setValue, setError, addField, removeField, reset } = utilityForm();

  return {
    utilityFields: fields,
    utilityErrors: errors,
    utilityErrorCount: errorCount,
    setUtilityValue: (props: SetUtilityFieldProps) => setValue(props),
    setUtilityError: (props: SetUtilityErrorProps) => setError(props),
    addUtility: addField,
    removeUtility: removeField,
    reset,
  };
};
