import React, { useState } from 'react';
import { Alert, Box, Button, Container, Form, Grid, Header, SpaceBetween, Spinner } from '@amzn/awsui-components-react';
import { FormInput } from '../common-components/formFields/FormInput';
import { FormSelect } from '../common-components/formFields/FormSelect';
import { FormSingleFileUpload } from '../common-components/formFields/FormSingleFileUpload';
import { BucketOptions, uploadFileIntoS3Bucket } from 'src/client/s3-client';

export enum UploadType {
  TRANSCRIPTIONS = 'TRANSCRIPTIONS',
  CORRECTIONS = 'CORRECTIONS',
}

export const BulkUpload = () => {
  const MAX_JOB_NAME = 128;
  const BULK_UPLOAD_BUCKET_MAX_FILE_SIZE = 20 * 1000 * 1000; // 20 MB

  interface SubmissionState {
    isSubmitting: boolean;
    error: boolean;
    success: boolean;
  }

  const [uploadJobName, setUploadJobName] = useState<string>('');
  const [uploadType, setUploadType] = useState<string>('');
  const [uploadFile, setUploadFile] = useState<File[]>([]);

  const [submissionState, setSubmissionState] = useState<SubmissionState>({
    isSubmitting: false,
    error: false,
    success: false,
  });

  const isFormValid = (): boolean =>
    uploadFile.length !== 0 &&
    uploadFile[0].size < BULK_UPLOAD_BUCKET_MAX_FILE_SIZE &&
    uploadJobName.trim().length !== 0 &&
    uploadType.trim().length !== 0;

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    if (!isFormValid() || submissionState.isSubmitting) {
      return;
    }

    setSubmissionState({
      isSubmitting: true,
      error: false,
      success: false,
    });

    try {
      const uploadedFileName = await uploadFileIntoS3Bucket(uploadFile[0], BucketOptions.BULK_UPLOAD);
      // TODO: Add API call with uploadedFileName

      setSubmissionState({
        isSubmitting: false,
        error: false,
        success: true,
      });
    } catch (error) {
      console.log(`Error uploading and submitting a file: ${error}`);
      setSubmissionState({
        isSubmitting: false,
        error: true,
        success: false,
      });
    }
  };

  return (
    <Grid
      gridDefinition={[
        { colspan: { xl: 4, l: 5, s: 6, xxs: 8 }, offset: { xl: 4, l: 4, s: 3, xxs: 2 } },
        { colspan: { xl: 4, l: 5, s: 6, xxs: 8 }, offset: { xl: 4, l: 4, s: 3, xxs: 2 } },
      ]}
    >
      <form data-testid="submitForm" onSubmit={handleSubmit}>
        <Form
          actions={
            <Button
              loadingText="Submitting bulk upload form"
              formAction="submit"
              variant="primary"
              disabled={submissionState.isSubmitting}
            >
              Submit Upload
            </Button>
          }
        >
          <Container header={<Header variant="h2">Bulk Upload Form</Header>}>
            <SpaceBetween direction="vertical" size="l">
              <FormInput
                value={uploadJobName}
                label="Upload Job Name"
                fieldName="jobName"
                description="A helpful name to reference this job by"
                fieldRequired
                fieldMaxLength={MAX_JOB_NAME}
                errorText="Job name field is required"
                onFieldChange={(event) => {
                  setUploadJobName(event.value);
                }}
                readOnly={submissionState.isSubmitting}
              />
              <FormSelect
                label="Upload Type"
                description="Select the upload type for the file"
                fieldName="uploadType"
                fieldRequired
                errorText="Upload type is required"
                selectedOption={{ label: uploadType, value: uploadType }}
                options={[
                  { label: UploadType.TRANSCRIPTIONS, value: UploadType.TRANSCRIPTIONS },
                  { label: UploadType.CORRECTIONS, value: UploadType.CORRECTIONS },
                ]}
                onFieldChange={(event) => {
                  setUploadType(event.value as UploadType);
                }}
                readOnly={submissionState.isSubmitting}
              />
              <FormSingleFileUpload
                label="Select File"
                description="Select a CSV file for data ingestion"
                fieldName="uploadFile"
                onFieldChange={(event) => {
                  if (!submissionState.isSubmitting) {
                    setUploadFile(event.value);
                  }
                }}
                accept=".csv"
                value={uploadFile}
                i18nStrings={{
                  uploadButtonText: (e) => 'Choose file',
                }}
                fieldRequired
                errorText="File is required"
                maxFileSize={BULK_UPLOAD_BUCKET_MAX_FILE_SIZE}
                maxFileSizeError="Max file size of 20 MB exceed"
              />
            </SpaceBetween>
          </Container>
        </Form>
      </form>
      <Box>
        {submissionState.error && (
          <Alert data-testid="alertError" type="error" header="Upload Failed" dismissible={false}>
            Failed to submit bulk upload form. Please try again with an updated csv.
          </Alert>
        )}
        {submissionState.success && (
          <Alert data-testid="alertError" type="success" header="Upload Succeeded" dismissible={false}>
            Bulk upload form submitted successfully.
          </Alert>
        )}
        {submissionState.isSubmitting && (
          <Box textAlign="center">
            <Spinner size="large" />
          </Box>
        )}
      </Box>
    </Grid>
  );
};
