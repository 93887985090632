import * as React from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import NotFoundPage from 'src/containers/404-page';
import { InvoiceDetails } from 'src/components/manifests/invoice-details';
import { CreateTemplate } from 'src/components/templates/create/index';
import { TemplateDetailsPage } from 'src/components/templates/templateDetails/template-details-page';
import { CreateWaterTemplateWizard } from 'src/components/templates/waterTemplates/CreateWaterTemplateWizard';
import { BlueprintDetailsPage } from 'src/components/blueprints/BlueprintDetailsPage';
import { InvoiceMetadataPage } from 'src/components/manifests/InvoiceMetadataPage';
import { BlueprintsPage } from 'src/components/blueprints/BlueprintsPage';
import { CreateBlueprintPage } from 'src/components/blueprints/createBlueprint/CreateBlueprintPage';
import { CreateBlueprintWizard } from 'src/components/blueprints/CreateBlueprintWizard';
import { HomePage } from 'src/components/home/HomePage';
import { BulkUpload } from './bulk-upload/bulk-upload';

export const PAGE_ROUTES = {
  template: `/template`,
  invoice: `/invoice`,
  invoices: `/invoices`,
  invoiceOverride: `/override`,
  createTemplate: `/create`,
  updateTemplate: `/update`,
  // TODO: Use `create-template` and `update-template` after Create Water Template code is merged
  createWaterTemplate: `/create-template`,
  updateWaterTemplate: `/update-template`,
  blueprint: `/blueprint`,
  blueprints: '/blueprints',
  updateBlueprint: `/update-blueprint`,
  createBlueprint: `/create-blueprint`,
  createBlueprintWizard: `/create-blueprint/wizard`,
  bulkUpload: `/bulk-upload`,
};

const Routes = () => (
  <Switch>
    <Route exact path="/">
      <HomePage />
    </Route>

    <Route exact path={`${PAGE_ROUTES.template}/:id`}>
      <TemplateDetailsPage />
    </Route>

    <Route exact path={`${PAGE_ROUTES.invoice}/:invoiceUuid`}>
      <InvoiceDetails />
    </Route>

    <Route exact path={`${PAGE_ROUTES.blueprints}`}>
      <BlueprintsPage />
    </Route>

    <Route exact path={`${PAGE_ROUTES.invoices}`}>
      <InvoiceMetadataPage />
    </Route>

    <Route exact path={`${PAGE_ROUTES.blueprint}/:blueprintId`}>
      <BlueprintDetailsPage />
    </Route>

    <Route exact path={`${PAGE_ROUTES.createBlueprint}`}>
      <CreateBlueprintPage />
    </Route>

    <Route exact path={`${PAGE_ROUTES.createBlueprintWizard}/:invoiceId`}>
      <CreateBlueprintWizard />
    </Route>

    <Route exact path={`${PAGE_ROUTES.updateBlueprint}/:blueprintId`}>
      <CreateBlueprintWizard />
    </Route>

    {/** TODO: After new Create Template code is merged, we will remove this duplicate route */}
    <Route exact path="/create/:invoiceId">
      <CreateTemplate />
    </Route>

    {/** TODO: After Create Water Template code is merged, we will remove this duplicate route */}
    <Route exact path="/update/:templateId">
      <CreateTemplate />
    </Route>

    <Route exact path="/create-template/:invoiceId">
      <CreateWaterTemplateWizard />
    </Route>

    <Route exact path="/update-template/:invoiceId">
      <CreateWaterTemplateWizard />
    </Route>

    <Route exact path={`${PAGE_ROUTES.bulkUpload}`}>
      <BulkUpload />
    </Route>
    <Route>
      <NotFoundPage />
    </Route>
  </Switch>
);

export default withRouter(Routes);
