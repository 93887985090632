import { ColumnLayout, SpaceBetween } from '@amzn/awsui-components-react';
import React, { useMemo } from 'react';
import { FormInput } from 'src/components/common-components/formFields/FormInput';
import { FormDatePicker } from 'src/components/common-components/formFields/FormDatePicker';
import { FormSelect } from '../../common-components/formFields/FormSelect';

import {
  SetTranscriptionTopLevelErrorProps,
  SetTranscriptionTopLevelFieldProps,
  TranscriptionTopLevelFormFieldState,
} from 'src/components/manifests/hooks/useInvoiceManualTranscriptionTopLevelForm';
import { createOptionsFromEnum, CURRENCY } from 'src/components/common-components/form/constants';

interface InvoiceManualTranscriptionTopLevelFieldsProps {
  topLevelFields: TranscriptionTopLevelFormFieldState;
  onChange: (args: SetTranscriptionTopLevelFieldProps) => void;
  onValidate: (args: SetTranscriptionTopLevelErrorProps) => void;
  isSubmitting: boolean;
}

export const InvoiceManualTranscriptionTopLevelFields: React.FC<InvoiceManualTranscriptionTopLevelFieldsProps> = ({
  topLevelFields,
  onChange,
  onValidate,
  isSubmitting,
}) => {
  const currencyOptions = useMemo(() => createOptionsFromEnum(CURRENCY), []);
  return (
    <section>
      <SpaceBetween size="xs">
        <fieldset>
          <legend>General</legend>
          <ColumnLayout columns={1}>
            <FormInput
              value={topLevelFields.accountNumber}
              label="Account Number"
              fieldName="accountNumber"
              isReadOnly={isSubmitting}
              fieldRequired
              onFieldChange={onChange}
              onFieldValidate={onValidate}
              errorText="Account Number field is required"
            />
          </ColumnLayout>
        </fieldset>

        <fieldset>
          <legend>Supplier</legend>
          <ColumnLayout columns={2}>
            <FormInput
              value={topLevelFields.supplierName}
              label="Supplier Name"
              fieldName="supplierName"
              isReadOnly={isSubmitting}
              onFieldChange={onChange}
              onFieldValidate={onValidate}
            />

            <FormInput
              value={topLevelFields.supplierAddress}
              label="Supplier Address"
              fieldName="supplierAddress"
              isReadOnly={isSubmitting}
              onFieldChange={onChange}
              onFieldValidate={onValidate}
            />
          </ColumnLayout>
        </fieldset>

        <fieldset>
          <legend>Charge</legend>
          <ColumnLayout columns={2}>
            <FormInput
              value={topLevelFields.totalChargeAmount}
              label="Total Charge Amount"
              fieldName="totalChargeAmount"
              isReadOnly={isSubmitting}
              onFieldChange={onChange}
              fieldRequired
              type="number"
              onFieldValidate={onValidate}
              errorText="Total charge amount field is required"
            />
            <FormSelect
              label="Total Charge Currency"
              selectedOption={
                topLevelFields?.totalChargeCurrency
                  ? { label: topLevelFields.totalChargeCurrency, value: topLevelFields.totalChargeCurrency }
                  : null
              }
              fieldRequired
              options={currencyOptions}
              fieldName="totalChargeCurrency"
              onFieldChange={onChange}
              onFieldValidate={onValidate}
              isReadOnly={isSubmitting}
              errorText="Charge currency field is required"
            />
          </ColumnLayout>
        </fieldset>

        <fieldset>
          <legend>Invoice Statement</legend>
          <ColumnLayout columns={3}>
            <FormDatePicker
              value={topLevelFields.billingStartDate}
              label="Bill Start Date"
              fieldName="billingStartDate"
              isReadOnly={isSubmitting}
              fieldRequired
              onFieldChange={onChange}
              onFieldValidate={onValidate}
              errorText="Bill start field is required"
            />

            <FormDatePicker
              value={topLevelFields.billingEndDate}
              label="Bill End Date"
              fieldName="billingEndDate"
              isReadOnly={isSubmitting}
              fieldRequired
              onFieldChange={onChange}
              onFieldValidate={onValidate}
              errorText="Bill end field is required"
            />

            <FormDatePicker
              value={topLevelFields.invoiceDate}
              label="Invoice Date"
              fieldName="invoiceDate"
              isReadOnly={isSubmitting}
              onFieldChange={onChange}
              onFieldValidate={onValidate}
            />
          </ColumnLayout>
        </fieldset>
      </SpaceBetween>
    </section>
  );
};
