import React from 'react';
import { DeprecatedUsage } from 'src/interface/type-def';
import { FieldType } from './map-to-schema';
import { TableOfProperties } from './TableOfProperties';
import { TemplatePropertySuggestedValues } from '../../templateSuggestions/templateSuggestionsUtils';

interface UsageSummaryProps {
  usages: DeprecatedUsage[];
  index: number;
  editTemplateValue: Function;
  excludeTemplateValue: Function;
  meterIndex?: number;
  suggestedTemplateValues: TemplatePropertySuggestedValues | null;
}

export const UsageSummary = ({
  usages,
  index,
  editTemplateValue,
  excludeTemplateValue,
  meterIndex,
  suggestedTemplateValues,
}: UsageSummaryProps) => (
  <TableOfProperties
    fieldType={FieldType.METERS_USAGES}
    fieldIndex={index}
    obj={usages[index]}
    editTemplateValue={editTemplateValue}
    excludeTemplateValue={excludeTemplateValue}
    meterIndex={meterIndex}
    suggestedTemplateValues={suggestedTemplateValues}
  />
);
