import { Transcription } from '@amzn/taprootinvoicemanagementservice-ts-client';
import { SetErrorCommonProps, SetFieldProps } from 'src/components/manifests/hooks/type';
import { useInvoiceManualTranscriptionGenericForm } from 'src/components/manifests/hooks/useInvoiceManualTranscriptionGenericForm';

type TranscriptionTopLevelFieldName = keyof TranscriptionTopLevelFormFieldState;
type TranscriptionTopLevelErrorFields = Set<TranscriptionTopLevelFieldName>;

export type SetTranscriptionTopLevelFieldProps = SetFieldProps<TranscriptionTopLevelFieldName>;
export type SetTranscriptionTopLevelErrorProps = SetErrorCommonProps<TranscriptionTopLevelFieldName>;

export type TranscriptionTopLevelFormFieldState = {
  supplierName: string;
  supplierAddress: string;
  accountNumber: string;
  invoiceDate: string;
  billingStartDate: string;
  billingEndDate: string;
  totalChargeAmount: string;
  totalChargeCurrency: string;
};

export const RequiredTopLevelFields = new Set<TranscriptionTopLevelFieldName>([
  'accountNumber',
  'billingStartDate',
  'billingEndDate',
  'totalChargeAmount',
  'totalChargeCurrency',
]);

type ErrorState<T> = { fields: T; count: number };
type TranscriptionTopLevelErrorsState = ErrorState<TranscriptionTopLevelErrorFields>;

const initializeTopLevelTranscriptionState = (transcription: Transcription): TranscriptionTopLevelFormFieldState => {
  const { totalCharge } = transcription;
  return {
    supplierName: transcription.supplierName ?? '',
    supplierAddress: transcription.supplierAddress ?? '',
    accountNumber: transcription.accountNumber ?? '',
    billingStartDate: transcription.billingStartDate ?? '',
    billingEndDate: transcription.billingEndDate ?? '',
    invoiceDate: transcription.invoiceDate ?? '',
    totalChargeAmount: totalCharge?.amount?.toString() ?? '',
    totalChargeCurrency: totalCharge?.currency ?? '',
  };
};

export const useInvoiceManualTranscriptionTopLevelForm = (transcription: Transcription) => {
  const topLevelForm = useInvoiceManualTranscriptionGenericForm<
    TranscriptionTopLevelFormFieldState,
    TranscriptionTopLevelFieldName
  >([transcription], RequiredTopLevelFields, (data) => [initializeTopLevelTranscriptionState(data[0])]);

  const { fields, errors, errorCount, setValue, setError, reset } = topLevelForm();

  return {
    topLevelFields: fields[0],
    topLevelErrors: errors[0],
    topLevelErrorCount: errorCount,
    setTopLevelValue: (props: SetTranscriptionTopLevelFieldProps) => setValue({ ...props, index: 0 }),
    setTopLevelError: (props: SetTranscriptionTopLevelErrorProps) => setError({ ...props, index: 0 }),
    reset,
  };
};
