import { useInvoiceManualTranscriptionTopLevelForm } from 'src/components/manifests/hooks/useInvoiceManualTranscriptionTopLevelForm';
import { useMeterForm } from 'src/components/manifests/hooks/useInvoiceManualTranscriptionMeterForm';
import { useUtilityForm } from 'src/components/manifests/hooks/useInvoiceManualTranscriptionUtilityForm';
import { useFetchAuthenticatedUser } from 'src/components/auth/hooks/useFetchAuthenticatedUser';
import { useCallback, useState } from 'react';
import { Transcription } from '@amzn/taprootinvoicemanagementservice-ts-client';

export interface UseHydrateInvoiceManualTranscriptionFormProps {
  transcription: Transcription;
  invoiceUuid: string;
  onSubmitSuccess?: () => void;
  onSubmitError?: (error?: string) => void;
}

export const useHydrateInvoiceManualTranscriptionForm = ({
  transcription,
  invoiceUuid,
  onSubmitSuccess = () => {},
  onSubmitError = (error?: string) => {},
}: UseHydrateInvoiceManualTranscriptionFormProps) => {
  const topLevel = useInvoiceManualTranscriptionTopLevelForm(transcription);
  const meter = useMeterForm(transcription.meterActivities ?? []);
  const utility = useUtilityForm(transcription.utilityActivities ?? []);

  const { isFetchingUser, fetchUserCredentials } = useFetchAuthenticatedUser();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [isSubmitSuccess, setIsSubmitSuccess] = useState<boolean>(false);

  const handleReset = useCallback(() => {
    topLevel.reset();
    meter.reset();
    utility.reset();
  }, [topLevel, meter, utility]);

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    if (!isSubmitDisabled) {
      setIsSubmitting(true);
      try {
        if (utility.utilityFields.length === 0 && meter.meterFields.length === 0) {
          throw new Error('You must provide at least one meter level data or utility level data');
        }
        const user = await fetchUserCredentials();
        if (user === null) throw new Error('Could not fetch user alias');
        // TODO: Make API call when it is ready
        console.log(`top level : ${JSON.stringify(topLevel)}`);
        console.log(`meter level : ${JSON.stringify(meter)}`);
        console.log(`utility level : ${JSON.stringify(utility)}`);
        setIsSubmitSuccess(true);
        onSubmitSuccess();
      } catch (err) {
        console.log('error submitting invoice manual transcription: ', err);
        setIsSubmitSuccess(false);
        onSubmitError((err as string).toString());
      } finally {
        setIsSubmitting(false);
      }
    }
  };

  const isSubmitDisabled =
    topLevel.topLevelErrorCount > 0 || meter.meterErrorCount > 0 || utility.utilityErrorCount > 0;

  return {
    handleSubmit,
    handleReset,
    isLoading,
    isSubmitting,
    isSubmitDisabled,
    isSubmitSuccess,
    ...topLevel,
    ...meter,
    ...utility,
  };
};
