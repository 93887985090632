import React from 'react';
import { Alert, Box, Button, Container, Spinner } from '@amzn/awsui-components-react';

import './Login.scss';
import { useFederatedSignIn } from './hooks/useFederatedSignIn';

export interface LoginProps {
  isFetchingUser: boolean;
}

export const Login = ({ isFetchingUser }: LoginProps) => {
  const { isAuthenticating, isSignInError, signInWithAmazonFederate } = useFederatedSignIn();

  return (
    <div className="login-container">
      <Container
        header={
          <Box variant="h2" textAlign="center">
            Taproot Invoice Management Website
          </Box>
        }
      >
        <Box textAlign="center" padding={{ horizontal: 's' }}>
          {isFetchingUser ? (
            <Box data-testid="fetching-user">
              <Spinner />
              <Box variant="span" margin={{ left: 's' }}>
                Fetching user credentials...
              </Box>
            </Box>
          ) : (
            <Button
              data-testid="signin-button"
              variant="primary"
              onClick={signInWithAmazonFederate}
              loading={isAuthenticating}
              loadingText="Signing in..."
            >
              Sign in with Midway
            </Button>
          )}
          {isSignInError && !isAuthenticating && (
            <Alert
              data-testid="signin-error"
              statusIconAriaLabel="Error"
              type="error"
              header="There was an error logging in"
            >
              Please try again. If this error persists, please reach out in the #tesseract Slack channel.
            </Alert>
          )}
        </Box>
      </Container>
    </div>
  );
};
