import { MeterActivity } from '@amzn/taprootinvoicemanagementservice-ts-client';
import { SetMultiFieldErrorProps, SetMultiFieldProps } from 'src/components/manifests/hooks/type';
import { useInvoiceManualTranscriptionGenericForm } from 'src/components/manifests/hooks/useInvoiceManualTranscriptionGenericForm';

type MeterActivityFieldName = keyof MeterActivityFormFieldState;
export type MeterActivityFormFieldState = {
  meterNumber: string;
  meterUtilityType: string;
  meterReadStartDate: string;
  meterReadEndDate: string;
  meterReadingStart: string;
  meterReadingEnd: string;
  meterChargeAmount: string;
  meterChargeCurrency: string;
  meterUsageAmount: string;
  meterUsageUnit: string;
};

export type SetMeterFieldProps = SetMultiFieldProps<MeterActivityFieldName>;

export type SetMeterErrorProps = SetMultiFieldErrorProps<MeterActivityFieldName>;

export const RequiredMeterFields = new Set<MeterActivityFieldName>([
  'meterUtilityType',
  'meterUsageAmount',
  'meterUsageUnit',
]);

const initializeMeterActivityValues = (meterActivities: Array<MeterActivity>): Array<MeterActivityFormFieldState> =>
  meterActivities.map((activity) => {
    const { meterCharge, meterUsage } = activity;
    return {
      meterNumber: activity.meterNumber ?? '',
      meterUtilityType: activity.meterUtilityType ?? '',
      meterReadStartDate: activity.meterReadStartDate ?? '',
      meterReadEndDate: activity.meterReadEndDate ?? '',
      meterReadingStart: activity.meterReadingStart?.toString() ?? '',
      meterReadingEnd: activity.meterReadingEnd?.toString() ?? '',
      meterChargeAmount: meterCharge?.amount?.toString() ?? '',
      meterChargeCurrency: meterCharge?.currency ?? '',
      meterUsageAmount: meterUsage?.amount?.toString() ?? '',
      meterUsageUnit: meterUsage?.unit ?? '',
    };
  });

export const useMeterForm = (initialMeterActivities: Array<MeterActivity>) => {
  const meterForm = useInvoiceManualTranscriptionGenericForm<MeterActivityFormFieldState, MeterActivityFieldName>(
    initialMeterActivities,
    RequiredMeterFields,
    initializeMeterActivityValues
  );

  const { fields, errors, errorCount, setValue, setError, addField, removeField, reset } = meterForm();

  return {
    meterFields: fields,
    meterErrors: errors,
    meterErrorCount: errorCount,
    setMeterValue: (props: SetMeterFieldProps) => setValue(props),
    setMeterError: (props: SetMeterErrorProps) => setError(props),
    addMeter: addField,
    removeMeter: removeField,
    reset,
  };
};
