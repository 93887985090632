// TODO: consume as Coral -> TS type from
// https://code.amazon.com/packages/TaprootTesseractDomain/blobs/mainline/--/src/taproot_tesseract_domain/model/currency.py

export enum CURRENCY {
  AED = 'AED',
  ARS = 'ARS',
  AUD = 'AUD',
  BRL = 'BRL',
  CAD = 'CAD',
  CHF = 'CHF',
  COP = 'COP',
  CZK = 'CZK',
  DKK = 'DKK',
  EUR = 'EUR',
  GBP = 'GBP',
  GTQ = 'GTQ',
  INR = 'INR',
  JPY = 'JPY',
  MXN = 'MXN',
  NOK = 'NOK',
  NZD = 'NZD',
  PLN = 'PLN',
  ROL = 'ROL',
  SEK = 'SEK',
  SGD = 'SGD',
  TRL = 'TRL',
  USD = 'USD',
  ZAR = 'ZAR',
}

// TODO: consume as Coral -> TS type from
// https://code.amazon.com/packages/TaprootTesseractDomain/blobs/1a9319e7f7ea76ef0cf518116fad6ee7eeb8677c/--/src/taproot_tesseract_domain/model/invoice/utility_unit.py#L9-L31

export enum USAGE_UNIT {
  BTU = 'BTU',
  MBTU = 'MBTU',
  MMBTU = 'MMBTU',
  THERM = 'THERM',
  DECATHERM = 'DECATHERM',
  GIGAJOULE = 'GIGAJOULE',
  KWH = 'KWH',
  MWH = 'MWH',
  CUBICMETER = 'CUBICMETER',
  LITER = 'LITER',
  KILOLITER = 'KILOLITER',
  GALLON = 'GALLON',
  IMPERIALGALLON = 'IMPERIALGALLON',
  HUNDREDGALLON = 'HUNDREDGALLON',
  KILOGALLON = 'KILOGALLON',
  MILLIONGALLON = 'MILLIONGALLON',
  CUBICFEET = 'CUBICFEET',
  TENCUBICFEET = 'TENCUBICFEET',
  HUNDREDCUBICFEET = 'HUNDREDCUBICFEET',
  THOUSANDCUBICFEET = 'THOUSANDCUBICFEET',
  HUNDRED = 'HUNDRED',
  THOUSAND = 'THOUSAND',
  TON = 'TON',
}

// TODO: consume as Coral -> TS type from
// https://code.amazon.com/packages/TaprootTesseractDomain/blobs/cc41eb9d27afe70e3e642cd02c0df5c94fa06a35/--/src/taproot_tesseract_domain/model/utility_type.py#L5-L8

export enum SERVICE_TYPE {
  ELECTRICITY = 'ELECTRICITY',
  NATURAL_GAS = 'NATURAL_GAS',
  WATER = 'WATER',
  WASTE = 'WASTE',
}

type OptionType = {
  value: string;
  label: string;
};

export function createOptionsFromEnum(enumObject: Record<string, string>): OptionType[] {
  return Object.entries(enumObject).map(([value, label]) => ({
    value,
    label,
  }));
}
