import React, { Dispatch, SetStateAction, useCallback, useState } from 'react';
import { getTimsInvoiceMetadata } from 'src/client/api-gateway';
import { InvoiceMetadata } from '@amzn/taprootinvoicemanagementservice-ts-client';

export interface UseFetchTimsInvoiceMetadataByUuid {
  invoiceUuid: string;
  setInvoiceUuid: Dispatch<SetStateAction<string>>;
  invoiceMetadata: InvoiceMetadata | null;
  invoiceMetadataLoading: boolean;
  invoiceMetadataError: string | null;
  fetchTimsInvoiceMetadataByUuid: (id: string) => Promise<void>;
}

export function useFetchTimsInvoiceMetadataByUuid(): UseFetchTimsInvoiceMetadataByUuid {
  const [invoiceUuid, setInvoiceUuid] = useState<string>('');
  const [invoiceMetadata, setInvoiceMetadata] = useState<InvoiceMetadata | null>(null);
  const [invoiceMetadataLoading, setInvoiceMetadataLoading] = useState(false);
  const [invoiceMetadataError, setInvoiceMetadataError] = useState<string | null>(null);

  const fetchTimsInvoiceMetadataByUuid = useCallback(async (id: string) => {
    setInvoiceUuid(id);
    setInvoiceMetadata(null);
    setInvoiceMetadataError(null);

    if (!id) return;

    try {
      setInvoiceMetadataLoading(true);
      const metadata = await getTimsInvoiceMetadata(id);
      setInvoiceMetadata(metadata || null);
    } catch (error: any) {
      const errorMessage = 'Unable to fetch invoice metadata.';
      setInvoiceMetadataError(errorMessage);
      console.error('Error fetching invoice metadata:', error);
    } finally {
      setInvoiceMetadataLoading(false);
    }
  }, []);

  return {
    invoiceUuid,
    setInvoiceUuid,
    invoiceMetadata,
    invoiceMetadataLoading,
    invoiceMetadataError,
    fetchTimsInvoiceMetadataByUuid,
  };
}
