import {
  FormField,
  FormFieldProps,
  DatePicker,
  DatePickerProps,
  NonCancelableCustomEvent,
} from '@amzn/awsui-components-react';
import React, { useState, useMemo, useEffect } from 'react';

interface FormDatePickerProps
  extends Omit<DatePickerProps, 'onChange' | 'onBlur'>,
    Pick<FormFieldProps, 'constraintText' | 'description' | 'errorText' | 'info' | 'label'> {
  fieldName: string;
  fieldError?: boolean;
  fieldRequired?: boolean;
  fieldIndex?: number | null;
  fieldMeterIndex?: number | null;
  onFieldChange: (args: any) => void;
  onFieldValidate?: (args: any) => void;
  isReadOnly?: boolean;
}

export const FormDatePicker: React.FC<FormDatePickerProps> = (props) => {
  const {
    value: initialValue = '',
    constraintText,
    description,
    label,
    info,
    fieldName,
    errorText,
    onFieldChange,
    fieldIndex = null,
    fieldMeterIndex = null,
    onFieldValidate = () => {},
    fieldError = false,
    fieldRequired = false,
    isReadOnly = false,
    placeholder = 'YYYY/MM/DD',
    ...additionalProps
  } = props;

  const [datePickerValue, setDatePickerValue] = useState<string>(initialValue);
  const [error, setError] = useState<boolean>(fieldRequired && !initialValue?.length);

  const commonEventArgs = useMemo(
    () => ({
      name: fieldName,
      ...(fieldIndex !== null && { index: fieldIndex }),
      ...(fieldMeterIndex !== null && { meterIndex: fieldMeterIndex }),
    }),
    [fieldName, fieldIndex, fieldMeterIndex]
  );

  useEffect(() => {
    setDatePickerValue(initialValue);
    setError(fieldRequired && !initialValue?.length);
  }, [initialValue, fieldRequired]);

  const handleDatePickerChange = (event: NonCancelableCustomEvent<DatePickerProps.ChangeDetail>) => {
    const { value } = event.detail;
    setDatePickerValue(value);
  };

  const handleDatePickerBlur = (event: NonCancelableCustomEvent<null>) => {
    onFieldChange({ value: datePickerValue ?? '', ...commonEventArgs });
    const isInvalid = fieldRequired && !datePickerValue.length;
    setError(isInvalid);
    onFieldValidate({ invalid: isInvalid, ...commonEventArgs });
  };

  return (
    <FormField
      constraintText={constraintText}
      description={description}
      label={label}
      info={info}
      errorText={error ? errorText : ''}
      stretch
    >
      <DatePicker
        readOnly={isReadOnly}
        onChange={handleDatePickerChange}
        onBlur={handleDatePickerBlur}
        value={datePickerValue}
        placeholder={placeholder}
        {...additionalProps}
      />
    </FormField>
  );
};
