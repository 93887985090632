import { FormField, FormFieldProps, Input, InputProps, NonCancelableCustomEvent } from '@amzn/awsui-components-react';
import React, { useState, useMemo, useEffect } from 'react';

interface FormInputProps
  extends Omit<InputProps, 'onChange' | 'onBlur'>,
    Pick<FormFieldProps, 'constraintText' | 'description' | 'errorText' | 'info' | 'label'> {
  isReadOnly?: boolean;
  fieldName: string;
  fieldRequired?: boolean;
  fieldIndex?: number | null;
  fieldMeterIndex?: number | null;
  fieldMaxLength?: number | null;
  onFieldChange: (args: any) => void;
  onFieldValidate?: (args: any) => void;
}

export const FormInput: React.FC<FormInputProps> = (props) => {
  const {
    constraintText,
    description,
    label,
    info,
    fieldName,
    fieldIndex = null,
    fieldMeterIndex = null,
    errorText,
    isReadOnly = false,
    onFieldChange,
    onFieldValidate = () => {},
    fieldRequired = false,
    value: initialValue = '',
    fieldMaxLength = null,
    ...additionalProps
  } = props;

  const [inputValue, setInputValue] = useState<string>(initialValue);
  const [error, setError] = useState<boolean>(fieldRequired && !initialValue?.length);

  useEffect(() => {
    setInputValue(initialValue);
    setError(fieldRequired && !initialValue?.length);
  }, [initialValue, fieldRequired]);

  const commonEventArgs = useMemo(
    () => ({
      name: fieldName,
      ...(fieldIndex !== null && { index: fieldIndex }),
      ...(fieldMeterIndex !== null && { meterIndex: fieldMeterIndex }),
    }),
    [fieldName, fieldIndex, fieldMeterIndex]
  );

  const handleInputChange = (event: NonCancelableCustomEvent<InputProps.ChangeDetail>) => {
    const { value } = event.detail;
    const maxValueLimitReach = fieldMaxLength && value?.length > fieldMaxLength;

    if (!maxValueLimitReach) {
      setInputValue(value);
    }
  };

  const handleInputBlur = (event: NonCancelableCustomEvent<null>) => {
    const isInvalid = fieldRequired && !inputValue?.length;

    setError(isInvalid);
    onFieldValidate?.({ invalid: isInvalid, ...commonEventArgs });
    onFieldChange({ value: inputValue, ...commonEventArgs });
  };

  return (
    <FormField
      constraintText={constraintText}
      description={description}
      label={label}
      info={info}
      errorText={error ? errorText : ''}
      stretch
    >
      <Input
        readOnly={isReadOnly}
        onChange={handleInputChange}
        value={inputValue}
        onBlur={handleInputBlur}
        {...additionalProps}
      />
    </FormField>
  );
};
