import React, { useMemo } from 'react';
import {
  createOptionsFromEnum,
  CURRENCY,
  SERVICE_TYPE,
  USAGE_UNIT,
} from 'src/components/common-components/form/constants';
import { Button, ColumnLayout, SpaceBetween } from '@amzn/awsui-components-react';
import { FormSelect } from 'src/components/common-components/formFields/FormSelect';
import { FormInput } from 'src/components/common-components/formFields/FormInput';
import {
  SetUtilityErrorProps,
  SetUtilityFieldProps,
  UtilityActivityFormFieldState,
} from 'src/components/manifests/hooks/useInvoiceManualTranscriptionUtilityForm';
import { AddFieldProps, RemoveFieldProps } from 'src/components/manifests/hooks/type';

interface UtilityFieldsProps {
  utilities: Array<UtilityActivityFormFieldState>;
  onChange: (args: SetUtilityFieldProps) => void;
  onValidate: (args: SetUtilityErrorProps) => void;
  onAdd: (args: AddFieldProps) => void;
  onRemove: (args: RemoveFieldProps) => void;
  isSubmitting: boolean;
}

export const InvoiceManualTranscriptionUtilityFields: React.FC<UtilityFieldsProps> = ({
  utilities,
  onChange,
  onValidate,
  onAdd,
  onRemove,
  isSubmitting,
}) => {
  const serviceTypeOptions = useMemo(() => createOptionsFromEnum(SERVICE_TYPE), []);
  const currencyOptions = useMemo(() => createOptionsFromEnum(CURRENCY), []);
  const unitOptions = useMemo(() => createOptionsFromEnum(USAGE_UNIT), []);

  return (
    <section>
      <div className="subsection__header">
        <h3>Utility Level Data</h3>
        <Button formAction="none" onClick={onAdd}>
          Add Utility Data
        </Button>
      </div>
      <div className="subsection__list">
        <SpaceBetween size="xs">
          {utilities.map((utility, index) => (
            <fieldset key={`utilityEntry${index}${utilities.length}`}>
              <legend>Utility #{index}</legend>
              <SpaceBetween size="xl">
                <div>
                  <SpaceBetween size="xs">
                    <fieldset>
                      <legend>General</legend>
                      <ColumnLayout columns={1}>
                        <FormSelect
                          label="Utility Type"
                          selectedOption={
                            utility?.utilityType
                              ? {
                                  label: utility.utilityType,
                                  value: utility.utilityType,
                                }
                              : null
                          }
                          options={serviceTypeOptions}
                          fieldName="utilityType"
                          onFieldChange={onChange}
                          onFieldValidate={onValidate}
                          fieldRequired
                          fieldIndex={index}
                          isReadOnly={isSubmitting}
                          errorText="Utility type field is required"
                        />
                      </ColumnLayout>
                    </fieldset>

                    <fieldset>
                      <legend>Usage</legend>
                      <ColumnLayout columns={2}>
                        <FormInput
                          value={utility.totalUsageAmount}
                          label="Total Usage Amount"
                          fieldName="totalUsageAmount"
                          type="number"
                          fieldRequired
                          onFieldChange={onChange}
                          onFieldValidate={onValidate}
                          fieldIndex={index}
                          isReadOnly={isSubmitting}
                          errorText="Total usage amount field is required"
                        />
                        <FormSelect
                          label="Total Usage Unit"
                          selectedOption={
                            utility?.totalUsageUnit
                              ? { label: utility.totalUsageUnit, value: utility.totalUsageUnit }
                              : null
                          }
                          options={unitOptions}
                          fieldName="totalUsageUnit"
                          fieldRequired
                          onFieldChange={onChange}
                          onFieldValidate={onValidate}
                          fieldIndex={index}
                          isReadOnly={isSubmitting}
                          errorText="Total usage unit field is required"
                        />
                      </ColumnLayout>
                    </fieldset>
                    <fieldset>
                      <legend>Charge</legend>
                      <ColumnLayout columns={2}>
                        <FormInput
                          value={utility.utilityChargeAmount}
                          label="Utility Charge Amount"
                          fieldName="utilityChargeAmount"
                          type="number"
                          fieldRequired
                          onFieldChange={onChange}
                          onFieldValidate={onValidate}
                          fieldIndex={index}
                          isReadOnly={isSubmitting}
                          errorText="Utility charge amount field is required"
                        />
                        <FormSelect
                          label="Utility Charge Currency"
                          selectedOption={
                            utility?.utilityChargeCurrency
                              ? { label: utility.utilityChargeCurrency, value: utility.utilityChargeCurrency }
                              : null
                          }
                          options={currencyOptions}
                          fieldRequired
                          fieldName="utilityChargeCurrency"
                          onFieldChange={onChange}
                          onFieldValidate={onValidate}
                          fieldIndex={index}
                          isReadOnly={isSubmitting}
                          errorText="Utility charge currency field is required"
                        />
                      </ColumnLayout>
                    </fieldset>
                    <fieldset>
                      <legend>Peak Demand</legend>
                      <ColumnLayout columns={2}>
                        <FormInput
                          value={utility.peakDemandUsageAmount}
                          label="Peak Demand Usage Amount"
                          fieldName="peakDemandUsageAmount"
                          type="number"
                          onFieldChange={onChange}
                          onFieldValidate={onValidate}
                          fieldIndex={index}
                          isReadOnly={isSubmitting}
                        />
                        <FormSelect
                          label="Peak Demand Usage Unit"
                          selectedOption={
                            utility?.peakDemandUsageUnit
                              ? { label: utility.peakDemandUsageUnit, value: utility.peakDemandUsageUnit }
                              : null
                          }
                          options={unitOptions}
                          fieldName="peakDemandUsageUnit"
                          onFieldChange={onChange}
                          onFieldValidate={onValidate}
                          fieldIndex={index}
                          isReadOnly={isSubmitting}
                        />
                      </ColumnLayout>
                    </fieldset>
                  </SpaceBetween>
                </div>
                <Button formAction="none" onClick={() => onRemove({ index })} disabled={utilities.length < 1}>
                  Remove Utility Data #{index}
                </Button>
              </SpaceBetween>
            </fieldset>
          ))}
        </SpaceBetween>
      </div>
    </section>
  );
};
