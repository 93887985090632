import {
  FormField,
  FormFieldProps,
  FileUploadProps,
  FileUpload,
  NonCancelableCustomEvent,
} from '@amzn/awsui-components-react';
import React, { useState, useMemo } from 'react';

interface FormSingleFileUploadProps
  extends Omit<FileUploadProps, 'onChange' | 'onBlur' | 'errorText'>,
    Pick<FormFieldProps, 'constraintText' | 'description' | 'errorText' | 'info' | 'label'> {
  fieldName: string;
  maxFileSize: number;
  maxFileSizeError: string;
  fieldRequired?: boolean;
  onFieldChange: (args: any) => void;
  onFieldValidate?: (args: any) => void;
}

export const FormSingleFileUpload: React.FC<FormSingleFileUploadProps> = (props) => {
  const {
    constraintText,
    description,
    label,
    info,
    fieldName,
    onFieldChange,
    onFieldValidate,
    accept,
    errorText,
    fieldRequired = false,
    maxFileSize,
    maxFileSizeError,
    value: initialValue = [],
    ...additionalProps
  } = props;

  const [inputFileValue, setFileInputValue] = useState<File[]>([...initialValue]);
  const [maxFileExceeded, setMaxFileExceeded] = useState<boolean>(
    initialValue.length === 1 && initialValue[0].size > maxFileSize
  );
  const [error, setError] = useState<boolean>(fieldRequired && !initialValue?.length);

  const commonEventArgs = useMemo(
    () => ({
      name: fieldName,
    }),
    [fieldName]
  );

  const handleFileChange = (event: NonCancelableCustomEvent<FileUploadProps.ChangeDetail>) => {
    const data = event.detail;
    const isInvalid = fieldRequired && !data.value?.length;
    const maxFileExceeded = data.value?.length === 1 && data.value[0].size > maxFileSize;

    onFieldValidate?.({ invalid: isInvalid, ...commonEventArgs });
    onFieldChange({
      value: data?.value ?? '',
      ...commonEventArgs,
    });

    setFileInputValue(data.value);
    setError(isInvalid);
    setMaxFileExceeded(maxFileExceeded);
  };

  return (
    <FormField
      constraintText={constraintText}
      description={description}
      label={label}
      info={info}
      errorText={error ? errorText : ''}
      stretch
    >
      <FileUpload
        onChange={handleFileChange}
        fileErrors={[maxFileExceeded ? maxFileSizeError : '']}
        accept={accept}
        value={inputFileValue}
        showFileLastModified
        showFileSize
        multiple={false}
        {...additionalProps}
      />
    </FormField>
  );
};
