import { Alert, Box, Container, Header, Spinner } from '@amzn/awsui-components-react';
import { useFetchInvoiceTranscriptionResults } from '../hooks/useFetchInvoiceTranscriptionResults';
import React, { useEffect } from 'react';
import { InvoiceDetailsParams } from '../invoice-details';
import { useParams } from 'react-router';
import { TranscriptionDetails } from './transcription-detail';
import { TranscriptionResult } from '@amzn/taprootinvoicemanagementservice-ts-client';

export const InvoiceTranscriptions = () => {
  const { invoiceUuid } = useParams<InvoiceDetailsParams>();
  const { isLoadingTranscriptions, invoiceTranscriptionResults, errorStatus, fetchInvoiceTranscriptionResults } =
    useFetchInvoiceTranscriptionResults();

  useEffect(() => {
    fetchInvoiceTranscriptionResults(invoiceUuid);
  }, [invoiceUuid, fetchInvoiceTranscriptionResults]);

  return (
    <Container header={<Header variant="h2">Invoice Transcriptions</Header>}>
      {isLoadingTranscriptions && (
        <span>
          <Spinner size="big" />
          Loading Transcriptions
        </span>
      )}
      {errorStatus && (
        <Alert statusIconAriaLabel="Info" type="error" header="Error Loading Transcriptions">
          Unable to load transcriptions for {invoiceUuid}
        </Alert>
      )}
      {invoiceTranscriptionResults && (
        <Box>
          {invoiceTranscriptionResults.map((transcription: TranscriptionResult, transcriptionIndex: number) =>
            TranscriptionDetails(transcription, transcriptionIndex)
          )}
        </Box>
      )}
      {!isLoadingTranscriptions && !errorStatus && invoiceTranscriptionResults?.length === 0 && (
        <Alert statusIconAriaLabel="Info" type="info" header="No Transcriptions">
          No transcriptions found for {invoiceUuid}
        </Alert>
      )}
    </Container>
  );
};
