import { Storage } from '@aws-amplify/storage';
import { getDevStageEnvOverrides, getStage, remoteStageConfig, Stage } from 'src/utils/env';

export enum BucketOptions {
  BULK_UPLOAD,
}

export const getBucketBasedOnBucketOption = (bucket: BucketOptions) => {
  const currentStage = getStage();
  const config = currentStage === Stage.DEV ? getDevStageEnvOverrides() : remoteStageConfig[currentStage];
  switch (bucket) {
    case BucketOptions.BULK_UPLOAD:
      return config.bulkUploadBucket;
    default:
      return '';
  }
};

export const uploadFileIntoS3Bucket = async (file: File, bucketOption: BucketOptions): Promise<string> => {
  const date = new Date().toISOString();
  const fileName = `${date}-${file.name}`;
  const bucket = getBucketBasedOnBucketOption(bucketOption);

  try {
    await Storage.put(fileName, file, {
      bucket,
      contentType: file.type,
      level: 'private',
    });
    return fileName;
  } catch (error) {
    throw new Error('Failed to upload file');
  }
};
