import React from 'react';
import { DeprecatedCharge } from 'src/interface/type-def';
import { FieldType } from './map-to-schema';
import { TableOfProperties } from './TableOfProperties';
import { TemplatePropertySuggestedValues } from '../../templateSuggestions/templateSuggestionsUtils';

interface ChargeSummaryProps {
  charges: DeprecatedCharge[];
  index: number;
  editTemplateValue: Function;
  excludeTemplateValue: Function;
  fieldType: FieldType;
  meterIndex?: number;
  suggestedTemplateValues: TemplatePropertySuggestedValues | null;
}

export const ChargeSummary = ({
  charges,
  index,
  editTemplateValue,
  excludeTemplateValue,
  fieldType,
  meterIndex,
  suggestedTemplateValues,
}: ChargeSummaryProps) => (
  <TableOfProperties
    fieldType={fieldType}
    fieldIndex={index}
    obj={charges[index]}
    editTemplateValue={editTemplateValue}
    excludeTemplateValue={excludeTemplateValue}
    meterIndex={meterIndex}
    suggestedTemplateValues={suggestedTemplateValues}
  />
);
