import { Table, StatusIndicator, Button, Toggle } from '@amzn/awsui-components-react';
import React from 'react';
import { Account, DeprecatedCharge, DeprecatedUsage, Meter, TemplateValue } from 'src/interface/type-def';
import { FieldType } from './map-to-schema';
import { getModifiableTemplateProperties } from '../createUtils';
import { TemplatePropertySuggestedValues } from '../../templateSuggestions/templateSuggestionsUtils';

interface TableOfPropertiesProps {
  fieldType: FieldType;
  fieldIndex?: number;
  obj: Partial<Account> | Partial<DeprecatedCharge> | Partial<DeprecatedUsage> | Partial<Meter>;
  editTemplateValue: Function;
  excludeTemplateValue: Function;
  meterIndex?: number;
  suggestedTemplateValues: TemplatePropertySuggestedValues | null;
}

export const TableOfProperties = ({
  fieldType,
  fieldIndex,
  obj,
  editTemplateValue,
  excludeTemplateValue,
  meterIndex,
  suggestedTemplateValues,
}: TableOfPropertiesProps) => (
  <div id={fieldType}>
    <Table
      columnDefinitions={[
        {
          id: 'name',
          header: 'Property',
          cell: (item) => {
            const propertyName = item[0];
            if (!propertyName) return '-';

            const numSuggestions =
              suggestedTemplateValues && propertyName in suggestedTemplateValues
                ? suggestedTemplateValues[propertyName].length
                : 0;

            return (
              <>
                {item[0]}{' '}
                {numSuggestions > 0 && (
                  <i>
                    ({numSuggestions} suggestion{numSuggestions > 1 && 's'})
                  </i>
                )}
              </>
            );
          },
        },
        {
          id: 'isConfigured',
          header: 'Configured?',
          cell: (item) => {
            const { valueRef } = item[1] as TemplateValue;
            return valueRef ? <StatusIndicator type="success" /> : <StatusIndicator type="error" />;
          },
        },
        {
          id: 'edit',
          header: 'Edit',
          cell: (item) => (
            <Button
              iconName="edit"
              variant="inline-icon"
              onClick={() => editTemplateValue(item, fieldType, fieldIndex, meterIndex)}
            />
          ),
        },
        {
          id: 'include',
          header: 'Include',
          cell: (item) => (
            <Toggle
              onChange={({ detail }) => excludeTemplateValue(item[0], obj, detail.checked)}
              checked={(item[1] as TemplateValue).include}
            />
          ),
        },
      ]}
      // Filter objects to only include properties that can be modified
      items={getModifiableTemplateProperties(obj)}
    />
  </div>
);
