import { TranscriptionResult } from '@amzn/taprootinvoicemanagementservice-ts-client';
import { useCallback, useState } from 'react';
import { getTranscriptionResults } from 'src/client/api-gateway';

export interface UseFetchInvoiceTranscriptionResultsState {
  isLoadingTranscriptions: boolean;
  invoiceTranscriptionResults: string;
  errorStatus: string;
  fetchInvoiceTranscriptionResults: (invoiceId: string) => Promise<void>;
}

export function useFetchInvoiceTranscriptionResults() {
  const [isLoadingTranscriptions, setIsLoadingTranscriptions] = useState<boolean>(false);
  const [invoiceTranscriptionResults, setInvoiceTranscriptionResults] = useState<TranscriptionResult[] | undefined>(
    undefined
  );
  const [errorStatus, setErrorStatus] = useState<boolean>(false);

  const fetchInvoiceTranscriptionResults = useCallback(async (invoiceId: string) => {
    setIsLoadingTranscriptions(true);
    setErrorStatus(false);
    setInvoiceTranscriptionResults(undefined);

    try {
      const transcriptionResultsResponse = await getTranscriptionResults(invoiceId);
      setInvoiceTranscriptionResults(transcriptionResultsResponse.transcriptionResults);
    } catch (error) {
      setErrorStatus(true);
    } finally {
      setIsLoadingTranscriptions(false);
    }
  }, []);

  return {
    isLoadingTranscriptions,
    invoiceTranscriptionResults,
    errorStatus,
    fetchInvoiceTranscriptionResults,
  };
}
