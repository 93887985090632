import {
  Box,
  Button,
  Container,
  NonCancelableCustomEvent,
  Link as PolarisLink,
  SpaceBetween,
  Spinner,
  Wizard,
  WizardProps,
} from '@amzn/awsui-components-react/polaris';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { cloneDeep, isEmpty } from 'lodash';
import { useHistory, useLocation } from 'react-router-dom';
import { apiPaths, apiPostRequest } from 'src/helpers/api';
import { Account, DeprecatedCharge, InvoiceMetadata, Meter, Template } from 'src/interface/type-def';
import { getPresigneds3Url } from '../../../helpers/storage';
import { initialTemplateState } from '../../../data-store/initial-state-new-template';
import { DefineMetadata } from './define-metadata';
import { MapTextractToSchema } from './mapToSchema/map-to-schema';
import ReviewAndCreate from './review-n-create';
import { APPLICATION_CONTEXT } from 'src/interface';
import { FlashMessage } from 'src/components/common-components/FlashMessage';
import { ErrorBoundary } from 'src/components/common-components/error-boundary';
import { getInvoice } from 'src/client/api-gateway';
import { filterFieldForIncludedProperties, getTemplateTypeFromId, isLegacyTemplate } from './createUtils';
import { HelpContext } from 'src/components/help/HelpProvider';
import {
  TemplateWizardStep1HelpContent,
  TemplateWizardStep2HelpContent,
} from 'src/components/manifests/help/TemplateWizardHelpContent';
import { useFetchTemplatesForInvoice } from '../hooks/useFetchTemplatesForInvoice';
import { useFetchInvoiceTextractResults } from 'src/components/textract/hooks/useFetchInvoiceTextractResults';
import { useFetchTemplateSuggestions, useGenerateSuggestedTemplateValues } from '../hooks/useTemplateSuggestions';
import { VerifyTemplate } from '../templateVerification/VerifyTemplate';
import { TemplateType } from '../types';
import { AppContext } from 'src/components/App';

export const ApplicationContext: APPLICATION_CONTEXT = {
  textractResultKey: '',
  textractResultTableKey: '',
  subTemplateMapState: { source: 'none', valueRef: { type: '', ref: {} } },
  setSubTemplateMapState: Function,
};
export const CreateTemplateContext = React.createContext(ApplicationContext);

const DEFAULT_TEMPLATE_TYPE = TemplateType.UTILITY;
// The Polaris `DatePicker` component expects time to be formatted like: `YYYY-MM-DD`.
const DEFAULT_TEMPLATE_VALID_FROM_DATE = '1970-01-01';

interface UseLocationState {
  template?: Template;
  manifest?: InvoiceMetadata;
  renderTemplateValidationStep?: boolean;
}

export const CreateTemplate: React.FC = () => {
  const history = useHistory();
  // Use the location state to determine if we are creating or updating a template
  const {
    state: { template: existingTemplate, manifest: existingInvoice, renderTemplateValidationStep },
  } = useLocation<UseLocationState>();
  const isUpdatingTemplate = Boolean(existingTemplate);

  const { user } = useContext(AppContext);

  const { isLoadingTemplates, utilityTemplate, siteTemplate, accountTemplate, fetchTemplatesForInvoice } =
    useFetchTemplatesForInvoice();
  const { textractResults, isLoadingTextractResults, fetchTextractResults, fetchTextractResultsError } =
    useFetchInvoiceTextractResults();
  const { isLoadingTemplateSuggestions, templateSuggestions, fetchTemplateSuggestions } = useFetchTemplateSuggestions();
  const { suggestedTemplateValues, generateSuggestedTemplateValues } = useGenerateSuggestedTemplateValues();

  const [manifest, setManifest] = useState<InvoiceMetadata | undefined>(existingInvoice);
  const [isLoadingManifest, setIsLoadingManifest] = useState<boolean>(false);
  const [pdfFile, setPdfFile] = useState<string>('');
  const [validDate, setValidDate] = React.useState<string>(DEFAULT_TEMPLATE_VALID_FROM_DATE);

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [submissionSuccess, setSubmissionSuccess] = useState<boolean>(false);
  const [submissionError, setSubmissionError] = useState<string>('');
  const [activeStepIndex, setActiveStepIndex] = useState<number>(0);

  const { setHelpContent, showHelp } = useContext(HelpContext);

  const initialTemplate =
    isUpdatingTemplate && existingTemplate
      ? existingTemplate
      : {
          ...initialTemplateState,
          createdFromInvoiceId: existingInvoice?.invoicePdfName || '',
        };
  const [newTemplate, setNewTemplate] = useState<Template>(initialTemplate);
  const [templateType, setTemplateType] = useState<TemplateType>(
    isUpdatingTemplate ? getTemplateTypeFromId(existingTemplate?.templateId || '') : DEFAULT_TEMPLATE_TYPE
  );

  const fetchManifestDataForTemplate = useCallback(async (manifestId: string) => {
    setIsLoadingManifest(true);
    return Promise.all([getInvoice(manifestId), getPresigneds3Url(manifestId)])
      .then((res) => {
        const [manifestResponse, pdfFileResponse] = res;

        setManifest(manifestResponse);
        setPdfFile(pdfFileResponse);

        return manifestResponse;
      })
      .finally(() => {
        setIsLoadingManifest(false);
      });
  }, []);

  const fetchExistingTemplatesForInvoice = useCallback(
    async (invoiceMetadata: InvoiceMetadata) => {
      if (isEmpty(invoiceMetadata)) return;

      const { manifestDocument } = invoiceMetadata;
      const { supplierNumber: utilityId, siteId, accountNumber: accountId } = manifestDocument;
      await fetchTemplatesForInvoice(utilityId, siteId, accountId);
    },
    [fetchTemplatesForInvoice]
  );

  useEffect(() => {
    const fetchDetailsForExistingTemplate = async (invoiceId: string) => {
      // Fetch invoice details and invoice PDF for the template being updated
      const existingInvoice = await fetchManifestDataForTemplate(invoiceId);
      await fetchExistingTemplatesForInvoice(existingInvoice);
    };

    const fetchDetailsForNewTemplate = async (invoice: InvoiceMetadata) => {
      const { invoicePdfName: invoiceId } = invoice;
      getPresigneds3Url(invoiceId).then((res) => setPdfFile(res));
      await Promise.all([fetchExistingTemplatesForInvoice(invoice), fetchTemplateSuggestions(invoiceId)]);
    };

    const isUpdatingTemplate = existingTemplate && !isEmpty(existingTemplate);

    if (isUpdatingTemplate) {
      const invoiceIdFromTemplate = existingTemplate.createdFromInvoiceId || '';
      fetchDetailsForExistingTemplate(invoiceIdFromTemplate);
      fetchTextractResults(invoiceIdFromTemplate);
    } else if (existingInvoice) {
      fetchDetailsForNewTemplate(existingInvoice);
      fetchTextractResults(existingInvoice.invoicePdfName);
    }
  }, []);

  useEffect(() => {
    if (
      isLoadingTemplateSuggestions ||
      isLoadingTextractResults ||
      !templateSuggestions ||
      !textractResults?.pageKeyValuesList.length
    ) {
      return;
    }
    // Based on the template suggestions, generate the TemplateValues that can be applied to this specific invoice
    generateSuggestedTemplateValues(templateSuggestions || [], textractResults.pageKeyValuesList);
  }, [isLoadingTemplateSuggestions, isLoadingTextractResults]);

  const handleInfoFollow = (helpContent: JSX.Element) => (e: CustomEvent<any>) => {
    e.preventDefault();

    setHelpContent(helpContent);
    showHelp(true);
  };

  /**
   * Returns request body for creating/updating a template. Filters out fields whose `include` property is not true.
   *
   * @returns create/update template request body
   */
  const createTemplateRequestBody = (): Template => {
    if (!newTemplate) return {};

    const templateCopy = cloneDeep(newTemplate) as Template;

    if (templateCopy.templateMap !== undefined) {
      const { templateMap } = templateCopy;
      const account = filterFieldForIncludedProperties(templateMap.account) as Account;
      const charges = templateMap.charges.map((charge) => filterFieldForIncludedProperties(charge) as DeprecatedCharge);

      const meters = templateMap.meters.map((meter) => {
        const { charges, usages } = meter;

        const filteredMeter = filterFieldForIncludedProperties(meter) as Meter;
        const filteredCharges = charges.map((charge) => filterFieldForIncludedProperties(charge));
        const filteredUsages = usages.map((usage) => filterFieldForIncludedProperties(usage));

        filteredMeter['charges'] = filteredCharges;
        filteredMeter['usages'] = filteredUsages;

        return filteredMeter;
      });

      const filteredTemplateMap = { ...templateMap, account, charges, meters };

      return { ...newTemplate, templateMap: filteredTemplateMap, updatedBy: user };
    }

    return { ...newTemplate, updatedBy: user };
  };

  const handleSubmitTemplate = async () => {
    setIsSubmitting(true);
    setSubmissionSuccess(false);
    setSubmissionError('');

    return apiPostRequest({
      path: apiPaths.templates,
      settings: {
        body: createTemplateRequestBody(),
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      },
    })
      .then(() => {
        setSubmissionSuccess(true);
      })
      .catch((err) => {
        setSubmissionError(err);
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const onNavigate = ({ detail }: NonCancelableCustomEvent<WizardProps.NavigateDetail>) => {
    const { requestedStepIndex } = detail;

    if (requestedStepIndex === 1) {
      setNewTemplate({
        ...newTemplate,
        utilityId: !isUpdatingTemplate ? manifest?.manifestDocument.supplierNumber : existingTemplate?.utilityId,
      });
    } else if (requestedStepIndex === 3) {
      if (renderTemplateValidationStep) {
        handleSubmitTemplate().then(() => setActiveStepIndex(detail.requestedStepIndex));
        return;
      }
    }
    setActiveStepIndex(detail.requestedStepIndex);
  };

  if (!existingInvoice && !existingTemplate) {
    return (
      <Container>
        <div style={{ textAlign: 'center' }}>
          <FlashMessage
            message="Select an invoice from the invoices list to create a new template, or a template from templates list to update an existing template"
            type="info"
            action={<Button onClick={() => history.push('/')}>Back to templates</Button>}
          />
          <h3>No Invoice Selected</h3>
          <p>Please select an invoice to create a template and avoid refreshing the page while creating template</p>
          <p>To update an existing template, select a template from the template list</p>
        </div>
        <Button onClick={() => history.push('/')} variant="primary">
          Start Over
        </Button>
      </Container>
    );
  }

  const isFetchingData =
    isLoadingManifest || isLoadingTemplates || isLoadingTextractResults || isLoadingTemplateSuggestions;

  if (isFetchingData) {
    return (
      <Container>
        <Box textAlign="center">
          <SpaceBetween direction="vertical" size="s">
            <Spinner size="large" />
            <span>Loading invoice details</span>
          </SpaceBetween>
        </Box>
      </Container>
    );
  }

  return (
    <CreateTemplateContext.Provider value={ApplicationContext}>
      {existingTemplate && (
        <FlashMessage
          data-testid="update-template-banner"
          type="info"
          message={`Updating template with ID: ${existingTemplate?.templateId}`}
        />
      )}
      <Wizard
        i18nStrings={{
          stepNumberLabel: (stepNumber) => `Step ${stepNumber}`,
          collapsedStepsLabel: (stepNumber, stepCount) => `Step ${stepNumber} of ${stepCount}`,
          cancelButton: 'Cancel',
          previousButton: 'Previous',
          nextButton: activeStepIndex === 2 ? 'Save and Next' : 'Next',
          submitButton: renderTemplateValidationStep ? 'Done' : 'Save template',
          optional: 'optional',
        }}
        onCancel={() => history.push('/')}
        onSubmit={renderTemplateValidationStep ? undefined : handleSubmitTemplate}
        isLoadingNextStep={activeStepIndex === 2 && isSubmitting}
        onNavigate={onNavigate}
        activeStepIndex={activeStepIndex}
        steps={[
          {
            title: 'Define Template Metadata',
            content: (
              <ErrorBoundary>
                <DefineMetadata
                  blankTemplate={initialTemplate}
                  newTemplate={newTemplate || {}}
                  setNewTemplate={setNewTemplate}
                  pdfFile={pdfFile}
                  existingInvoice={manifest}
                  templateToUpdate={existingTemplate || {}}
                  isUpdatingTemplate={isUpdatingTemplate}
                  templateType={templateType}
                  setTemplateType={setTemplateType}
                  existingUtilityTemplate={isLegacyTemplate(utilityTemplate) ? utilityTemplate : null}
                  existingSiteTemplate={isLegacyTemplate(siteTemplate) ? siteTemplate : null}
                  existingAccountTemplate={isLegacyTemplate(accountTemplate) ? accountTemplate : null}
                  validDate={validDate}
                  setValidDate={(date: string) => setValidDate(date)}
                  suggestedTemplateValues={suggestedTemplateValues}
                />
              </ErrorBoundary>
            ),
            info: (
              <PolarisLink variant="info" onFollow={handleInfoFollow(TemplateWizardStep1HelpContent)}>
                Info
              </PolarisLink>
            ),
          },
          {
            title: 'Map Textract Results to Schema',
            content: (
              <>
                <ErrorBoundary>
                  <MapTextractToSchema
                    pdfFile={pdfFile}
                    template={newTemplate || {}}
                    setTemplate={setNewTemplate}
                    manifest={manifest}
                    templateType={templateType}
                    textractResults={textractResults}
                    suggestedTemplateValues={suggestedTemplateValues}
                  />
                </ErrorBoundary>
              </>
            ),
            info: (
              <PolarisLink variant="info" onFollow={handleInfoFollow(TemplateWizardStep2HelpContent)}>
                Info
              </PolarisLink>
            ),
          },
          {
            title: 'Review and Save Template',
            content: (
              <ErrorBoundary>
                <SpaceBetween direction="vertical" size="s">
                  <ReviewAndCreate
                    setActiveStepIndex={setActiveStepIndex}
                    newTemplate={newTemplate || {}}
                    manifest={manifest}
                  />
                  <div>
                    {isSubmitting && (
                      <FlashMessage message="Saving changes to this template" type="in-progress" dismissible={false} />
                    )}
                  </div>
                  <div>
                    {submissionSuccess && (
                      <FlashMessage
                        header="Template created successfully"
                        message={`Your changes have been saved. ${
                          renderTemplateValidationStep
                            ? 'We recommend proceeding to the next step to test your template with up to 10 invoices.'
                            : 'Return to the home page to reprocess invoices.'
                        }`}
                        type="success"
                      />
                    )}
                  </div>
                  <div>
                    {submissionError && (
                      <FlashMessage
                        header="There was an error creating this template"
                        message={`Error: ${submissionError}`}
                        type="error"
                      />
                    )}
                  </div>
                </SpaceBetween>
              </ErrorBoundary>
            ),
          },
          ...(renderTemplateValidationStep
            ? [
                {
                  title: 'Verify Template',
                  content: <VerifyTemplate isVerifyingBlueprint={false} template={newTemplate} />,
                },
              ]
            : []),
        ]}
      />
    </CreateTemplateContext.Provider>
  );
};

// todo: Check to make sure minimum field are met before submitting template for creation
function meetMinCreateReq() {
  return true;
}
