import {
  Badge,
  Box,
  Button,
  ColumnLayout,
  Container,
  Grid,
  Header,
  Icon,
  Link as AWSUILink,
  SpaceBetween,
} from '@amzn/awsui-components-react';
import React from 'react';
import { Link } from 'react-router-dom';
import { BreadcrumbGroupWithRouting } from 'src/components/breadcrumbs/BreadcrumbGroupWithRouting';
import { ROOT_BREADCRUMB } from 'src/components/breadcrumbs/breadcrumbs';

import 'src/components/home/HomePage.scss';
import { PAGE_ROUTES } from '../Routes';
import { MoreResourcesContainer } from './MoreResourcesContainer';

export const HomePage = () => (
  <Box>
    <SpaceBetween size="l">
      <BreadcrumbGroupWithRouting
        items={[
          {
            text: ROOT_BREADCRUMB.homepage.text,
            href: ROOT_BREADCRUMB.homepage.getHref(),
          },
        ]}
      />
      <Box margin={{ bottom: 'xl' }}>
        <Grid
          gridDefinition={[
            { colspan: { xl: 6, l: 5, s: 6, xxs: 10 }, offset: { l: 2, xxs: 1 } },
            { colspan: { xl: 2, l: 3, s: 4, xxs: 10 }, offset: { s: 0, xxs: 1 } },
          ]}
        >
          <Box>
            <Box variant="h1" fontWeight="heavy" fontSize="display-l" color="inherit">
              TIMW
            </Box>
            <Box fontWeight="normal" padding={{ bottom: 's' }} fontSize="display-l" color="inherit">
              Taproot Invoice Management Website
            </Box>
          </Box>

          <Container header={<Header variant="h2">Bulk Upload</Header>}>
            <SpaceBetween size="l">
              <Box> Bulk upload invoice transcriptions/corrections</Box>
              <Button variant="primary">Bulk Upload</Button>
            </SpaceBetween>
          </Container>
        </Grid>
      </Box>

      <Grid
        gridDefinition={[
          { colspan: { xl: 6, l: 5, s: 6, xxs: 10 }, offset: { l: 2, xxs: 1 } },
          { colspan: { xl: 2, l: 3, s: 4, xxs: 10 }, offset: { s: 0, xxs: 1 } },
        ]}
      >
        <Container header={<Header variant="h2">What is TIMW ? </Header>}>
          <ColumnLayout columns={1}>
            <Box>
              <Box padding={{ right: 'xs' }} display="inline">
                <Badge color="blue">1</Badge>
              </Box>
              TIMW stands for Taproot Invoice Management Website, it manages the lifecycle of an invoice. You can view
              an invoice or its transcription, transcribe an invoice in this website.
            </Box>
            <Box>
              <Box padding={{ right: 'xs' }} display="inline">
                <Badge color="blue">2</Badge>
              </Box>
              Bulk upload. TIMW supports a bulk upload for invoices' transcriptions or correction.
            </Box>
          </ColumnLayout>
        </Container>
        <MoreResourcesContainer />
      </Grid>
    </SpaceBetween>
  </Box>
);
