/**
 * Formats an ISO 8601 timestamp into a human-readable string in the format "YYYY-MM-DD, h:mmAM/PM".
 *
 * @param timestamp - The ISO 8601 timestamp string to format (e.g., "2024-09-27T16:59:27.084504008Z").
 * @returns A formatted string representing the date and time (e.g., "2024-09-27, 4:59PM").
 */

export function formatTimestamp(timestamp: string | any): string {
  const date = new Date(timestamp);

  // Ensure the timestamp is valid
  if (Number.isNaN(date.getTime())) {
    return '-';
  }
  // Extract the YYYY-MM-DD part
  const formattedDate = date.toISOString().split('T')[0];

  // Format the time with 12-hour clock and AM/PM
  const timeOptions: Intl.DateTimeFormatOptions = {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
    timeZone: 'UTC',
  };
  const formattedTime = new Intl.DateTimeFormat('en-US', timeOptions).format(date);

  return `${formattedDate}, ${formattedTime}`;
}
