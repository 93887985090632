import React, { useMemo } from 'react';
import {
  createOptionsFromEnum,
  CURRENCY,
  SERVICE_TYPE,
  USAGE_UNIT,
} from 'src/components/common-components/form/constants';
import { Button, ColumnLayout, SpaceBetween } from '@amzn/awsui-components-react';
import {
  MeterActivityFormFieldState,
  SetMeterErrorProps,
  SetMeterFieldProps,
} from 'src/components/manifests/hooks/useInvoiceManualTranscriptionMeterForm';
import { AddFieldProps, RemoveFieldProps } from 'src/components/manifests/hooks/type';
import { FormInput } from 'src/components/common-components/formFields/FormInput';
import { FormSelect } from 'src/components/common-components/formFields/FormSelect';
import { FormDatePicker } from 'src/components/common-components/formFields/FormDatePicker';

interface MeterFieldsProps {
  meters: Array<MeterActivityFormFieldState>;
  onChange: (args: SetMeterFieldProps) => void;
  onValidate: (args: SetMeterErrorProps) => void;
  onAdd: (args: AddFieldProps) => void;
  onRemove: (args: RemoveFieldProps) => void;
  isSubmitting: boolean;
}

export const InvoiceManualTranscriptionMeterFields: React.FC<MeterFieldsProps> = ({
  meters,
  onChange,
  onValidate,
  onAdd,
  onRemove,
  isSubmitting,
}) => {
  const serviceTypeOptions = useMemo(() => createOptionsFromEnum(SERVICE_TYPE), []);
  const currencyOptions = useMemo(() => createOptionsFromEnum(CURRENCY), []);
  const unitOptions = useMemo(() => createOptionsFromEnum(USAGE_UNIT), []);

  return (
    <section>
      <div className="subsection__header">
        <h3>Meter Level Data</h3>
        <Button formAction="none" onClick={onAdd}>
          Add Meter
        </Button>
      </div>
      <div className="subsection__list">
        <SpaceBetween size="xs">
          {meters.map((meter, index) => (
            <fieldset key={`meterEntry${index}${meters.length}`}>
              <legend>Meter #{index}</legend>
              <SpaceBetween size="xl">
                <div>
                  <SpaceBetween size="xs">
                    <fieldset>
                      <legend>General</legend>
                      <ColumnLayout columns={2}>
                        <FormInput
                          value={meter.meterNumber}
                          label="Meter Number"
                          fieldName="meterNumber"
                          onFieldChange={onChange}
                          onFieldValidate={onValidate}
                          fieldIndex={index}
                          isReadOnly={isSubmitting}
                        />
                        <FormSelect
                          label="Meter Type"
                          selectedOption={
                            meter?.meterUtilityType
                              ? { label: meter.meterUtilityType, value: meter.meterUtilityType }
                              : null
                          }
                          options={serviceTypeOptions}
                          fieldName="meterUtilityType"
                          onFieldChange={onChange}
                          onFieldValidate={onValidate}
                          fieldRequired
                          fieldIndex={index}
                          isReadOnly={isSubmitting}
                          errorText="Meter utility type field is required"
                        />
                      </ColumnLayout>
                    </fieldset>
                    <fieldset>
                      <legend>Meter Charge</legend>
                      <ColumnLayout columns={2}>
                        <FormInput
                          value={meter.meterChargeAmount}
                          label="Meter Charge Amount"
                          fieldName="meterChargeAmount"
                          type="number"
                          onFieldChange={onChange}
                          onFieldValidate={onValidate}
                          fieldIndex={index}
                          isReadOnly={isSubmitting}
                        />
                        <FormSelect
                          label="Meter Charge Currency"
                          selectedOption={
                            meter?.meterChargeCurrency
                              ? { label: meter.meterChargeCurrency, value: meter.meterChargeCurrency }
                              : null
                          }
                          options={currencyOptions}
                          fieldName="meterChargeCurrency"
                          onFieldChange={onChange}
                          onFieldValidate={onValidate}
                          fieldIndex={index}
                          isReadOnly={isSubmitting}
                        />
                      </ColumnLayout>
                    </fieldset>
                    <fieldset>
                      <legend>Meter Usage</legend>
                      <ColumnLayout columns={2}>
                        <FormInput
                          value={meter.meterUsageAmount}
                          label="Meter Usage Amount"
                          fieldName="meterUsageAmount"
                          type="number"
                          fieldRequired
                          onFieldChange={onChange}
                          onFieldValidate={onValidate}
                          fieldIndex={index}
                          isReadOnly={isSubmitting}
                          errorText="Meter usage amount field is required"
                        />
                        <FormSelect
                          label="Meter Usage Unit"
                          selectedOption={
                            meter?.meterUsageUnit ? { label: meter.meterUsageUnit, value: meter.meterUsageUnit } : null
                          }
                          options={unitOptions}
                          fieldName="meterUsageUnit"
                          fieldRequired
                          onFieldChange={onChange}
                          onFieldValidate={onValidate}
                          fieldIndex={index}
                          isReadOnly={isSubmitting}
                          errorText="Meter usage unit field is required"
                        />
                      </ColumnLayout>
                    </fieldset>
                    <fieldset>
                      <legend>Meter Reading</legend>
                      <ColumnLayout columns={2}>
                        <FormInput
                          value={meter.meterReadingStart}
                          label="Meter Reading Start"
                          fieldName="meterReadingStart"
                          type="number"
                          onFieldChange={onChange}
                          onFieldValidate={onValidate}
                          fieldIndex={index}
                          isReadOnly={isSubmitting}
                        />
                        <FormInput
                          value={meter.meterReadingEnd}
                          label="Meter Reading End"
                          fieldName="meterReadingEnd"
                          type="number"
                          onFieldChange={onChange}
                          onFieldValidate={onValidate}
                          fieldIndex={index}
                          isReadOnly={isSubmitting}
                        />
                      </ColumnLayout>
                    </fieldset>
                    <fieldset>
                      <legend>Meter Period</legend>
                      <ColumnLayout columns={2}>
                        <FormDatePicker
                          value={meter.meterReadStartDate}
                          label="Read Start Date"
                          fieldName="meterReadStartDate"
                          onFieldChange={onChange}
                          onFieldValidate={onValidate}
                          fieldIndex={index}
                          isReadOnly={isSubmitting}
                        />
                        <FormDatePicker
                          value={meter.meterReadEndDate}
                          label="Read End Date"
                          fieldName="meterReadEndDate"
                          onFieldChange={onChange}
                          onFieldValidate={onValidate}
                          fieldIndex={index}
                          isReadOnly={isSubmitting}
                        />
                      </ColumnLayout>
                    </fieldset>
                  </SpaceBetween>
                </div>
                <Button formAction="none" onClick={() => onRemove({ index })} disabled={meters.length < 1}>
                  Remove Meter #{index}
                </Button>
              </SpaceBetween>
            </fieldset>
          ))}
        </SpaceBetween>
      </div>
    </section>
  );
};
